import React, { useState } from 'react';
import { Search, X, Calendar, Hash, Type, Check, Settings, CalendarClock } from 'lucide-react';

const GroupedFilterSelector = ({ filters = {}, onSelectFilter, onCancel }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const groupFilters = () => {
    const grouped = {};

    Object.entries(filters).forEach(([key, filter]) => {
      if (filter.group) {
        if (!grouped[filter.group]) {
          grouped[filter.group] = [];
        }
      }
    });

    grouped['Other'] = [];

    Object.entries(filters).forEach(([key, filter]) => {
      if (filter.isAdvanced) {
        const group = filter.group || 'Other';
        grouped[group].push({
          id: key,
          label: filter.label || key,
          icon: filter.icon,
          type: filter.type || 'default',
        });
      }
    });

    // Remove empty groups
    Object.keys(grouped).forEach((group) => {
      if (grouped[group].length === 0) {
        delete grouped[group];
      }
    });

    return grouped;
  };

  const groupedFilters = groupFilters();

  const getFilterIcon = (filter) => {
    if (filter.icon) return filter.icon;

    switch (filter.type) {
      case 'date':
        return <Calendar className="tw-h-4 tw-w-4 tw-text-gray-500" />;
      case 'dateRange':
        return <CalendarClock className="tw-h-4 tw-w-4 tw-text-gray-500" />;
      case 'number':
        return <Hash className="tw-h-4 tw-w-4 tw-text-gray-500" />;
      case 'text':
        return <Type className="tw-h-4 tw-w-4 tw-text-gray-500" />;
      case 'boolean':
        return <Check className="tw-h-4 tw-w-4 tw-text-gray-500" />;
      default:
        return <Settings className="tw-h-4 tw-w-4 tw-text-gray-500" />;
    }
  };

  const filterItems = () => {
    if (!searchTerm) return groupedFilters;

    const filtered = {};

    Object.entries(groupedFilters).forEach(([group, items]) => {
      const matchingItems = items.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()));

      if (matchingItems.length > 0) {
        filtered[group] = matchingItems;
      }
    });

    return filtered;
  };

  const filteredGroups = filterItems();

  return (
    <div className="tw-w-full">
      <div className="tw-p-4 tw-border-b">
        <div className="tw-relative">
          <input
            type="text"
            className="tw-w-full tw-pl-8 tw-py-[4.5px] tw-border tw-rounded-md focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
            placeholder="Search in contact properties"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="tw-absolute tw-left-2 tw-top-1/2 -tw-translate-y-1/2 tw-h-4 tw-w-4 tw-text-gray-400" />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="tw-absolute tw-right-2 tw-top-1/2 -tw-translate-y-1/2 tw-text-gray-400 hover:tw-text-gray-600"
            >
              <X className="tw-h-4 tw-w-4" />
            </button>
          )}
        </div>
      </div>
      <div className="tw-max-h-[400px] tw-overflow-y-auto">
        {Object.keys(filteredGroups).length > 0 ? (
          Object.entries(filteredGroups).map(([group, items]) => (
            <div key={group} className="tw-mb-4">
              {(group !== 'Other' || Object.keys(filteredGroups).length > 1) && (
                <div className="tw-px-4 tw-py-2 tw-font-medium tw-text-gray-700 tw-bg-gray-50">{group}</div>
              )}

              {items.map((filter) => (
                <div
                  key={filter.id}
                  className="tw-flex tw-items-center tw-gap-3 tw-px-4 tw-py-3 tw-border-b tw-cursor-pointer hover:tw-bg-gray-50"
                  onClick={() => onSelectFilter(filter.id)}
                >
                  <div className="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-text-blue-500">
                    {getFilterIcon(filter)}
                  </div>
                  <span className="tw-text-sm">{filter.label}</span>
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="tw-p-4 tw-text-center tw-text-gray-500">No matching filters found</div>
        )}
      </div>
      <div className="tw-p-4 tw-border-t tw-flex tw-justify-end">
        <button
          onClick={onCancel}
          className="tw-px-4 tw-py-2 tw-text-sm tw-text-gray-600 tw-border tw-rounded-md hover:tw-bg-gray-50"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default GroupedFilterSelector;
