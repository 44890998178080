import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import moment from 'moment';

const OptimizedDateFilter = ({
  value = [],
  onChange,
  operator = 'LT', // 'before', 'onOrBefore', 'on', 'after', 'onOrAfter', 'between'
}) => {
  const [startDate, setStartDate] = useState(value[0] ? moment(value[0]) : null);
  const [isFocussed, setIsFocussed] = useState(false);

  const handleStartDateChange = (date) => {
    setStartDate(date);

    onChange([date ? date.format('YYYY-MM-DD') : null]);
  };

  const getPlaceholderText = () => {
    switch (operator) {
      case 'LT':
        return 'Before...';
      case 'LTE':
        return 'On or before...';
      case 'EQ':
        return 'Exact date...';
      case 'GT':
        return 'After...';
      case 'GTE':
        return 'On or after...';
      default:
        return 'Select date...';
    }
  };

  return (
    <div className="tw-w-full">
      <div className="tw-flex tw-flex tw-gap-4">
        <div className="tw-w-full tw-flex">
          <div className="tw-mb-2 tw-text-sm tw-text-gray-500 tw-mr-3">
            {operator === 'between' ? 'Start date' : 'Date'}
          </div>
          <div className="tw-w-full">
            <SingleDatePicker
              date={startDate}
              focused={isFocussed}
              onDateChange={handleStartDateChange}
              onFocusChange={({ focused }) => setIsFocussed(focused)}
              numberOfMonths={1}
              placeholder={getPlaceholderText()}
              isOutsideRange={() => false}
              id="date_filter"
              hideKeyboardShortcutsPanel
              small
              noBorder
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptimizedDateFilter;
