import React, { useState, useCallback, useEffect } from 'react';
import { Plus } from 'lucide-react';
import FilterButton from './FilterButton';
import AdvancedFilters from './components/AdvancedFilters';
import { v4 as uuidv4 } from 'uuid';

const FilterBar = ({
  filters = {},
  filterData = {},
  loadingFilters = [],
  onAddFilter,
  onFilterChange = () => {},
  requiredFilters = [],
  value = [],
}) => {
  const [activeFilters, setActiveFilters] = useState(value);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setActiveFilters(value);
  }, []);

  useEffect(() => {
    value.forEach((curr) => {
      onAddFilter(curr.type);
    });
  }, []);

  const handleClickOutside = useCallback((e) => {
    if (!e.target.closest('.filter-dropdown')) {
      setShowDropdown(false);
      setShowFilterOptions(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  const addFilter = async (filterName) => {
    await onAddFilter?.(filterName);
    setActiveFilters((prev) => [
      ...prev,
      {
        type: filterName,
        id: uuidv4(),
        operator: filters[filterName].defaultValue.operator,
        value: filters[filterName].defaultValue.value,
      },
    ]);
    setShowDropdown(false);
    setShowFilterOptions(filterName);
  };

  const removeFilter = (filter) => {
    if (requiredFilters.includes(filter)) return;
    const newFilters = [...activeFilters].filter((a) => a.id !== filter);
    setActiveFilters(newFilters);
    onFilterChange(newFilters);
  };

  const updateFilterValue = (filterId, filterName, value, operator) => {
    const newFilters = activeFilters.reduce((acc, filter) => {
      if (filter.id === filterId) {
        acc.push({
          ...filter,
          type: filterName,
          value: value,
          operator: operator || filter.operator,
        });
      } else {
        acc.push(filter);
      }
      return acc;
    }, []);

    if (!newFilters.some((filter) => filter.id === filterId)) {
      newFilters.push({
        type: filterName,
        id: filterId || uuidv4(),
        operator: operator || filters[filterName].defaultValue.operator,
        value: value,
      });
    }

    setActiveFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleAdvancedFilterChange = (newFilters) => {
    const standardFilters = activeFilters.filter((filter) => !filters[filter.type].isAdvanced);

    const combinedFilters = [...standardFilters];

    newFilters.forEach((newFilter) => {
      const existingIndex = combinedFilters.findIndex((f) => f.id === newFilter.id);

      if (existingIndex >= 0) {
        combinedFilters[existingIndex] = newFilter;
      } else {
        combinedFilters.push(newFilter);
      }
    });

    setActiveFilters(combinedFilters);
    onFilterChange(combinedFilters);
  };

  const listOfKeys = activeFilters.map((a) => a.type);
  const remainingFilters = Object.keys(filters).filter(
    (filter) => !filters[filter].isAdvanced && !listOfKeys.includes(filter),
  );

  const hasAdvancedFilters = Object.keys(filters).filter((filter) => filters[filter].isAdvanced);

  const renderFilterComponent = (filterName, filterId) => {
    if (loadingFilters.includes(filterName)) {
      return (
        <div className="tw-p-4 tw-text-center">
          <div className="tw-animate-spin tw-h-5 tw-w-5 tw-border-2 tw-border-blue-500 tw-border-t-transparent tw-rounded-full tw-mx-auto" />
          <div className="tw-mt-2 tw-text-sm tw-text-gray-500">Loading...</div>
        </div>
      );
    }

    const FilterComponent = filters[filterName].component;

    const foundFilter = activeFilters.find((val) => val.id === filterId);
    return (
      <FilterComponent
        {...filters[filterName]}
        {...filterData[filterName]}
        value={foundFilter?.value}
        onChange={(value) => {
          updateFilterValue(filterId, filterName, value);
        }}
      />
    );
  };

  const standardFilters = activeFilters.reduce((acc, curr) => {
    if (!filters[curr.type].isAdvanced) {
      acc.push(curr);
    }

    return acc;
  }, []);

  return (
    <div className="tw-w-full tw-bg-white">
      <div className="tw-p-4 tw-border-b">
        <div className="tw-flex tw-items-center tw-gap-2 tw-flex-wrap tw-ml-2">
          Filter by:
          {standardFilters.map((fil) => {
            return (
              <div key={fil.id} className="tw-relative filter-dropdown">
                <FilterButton
                  filter={fil.type}
                  value={fil?.value}
                  label={filters[fil.type]?.label}
                  options={filterData[fil.type]?.options || filters[fil.type].options || []}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFilterOptions(showFilterOptions === fil.type ? null : fil.type);
                    setShowDropdown(false);
                  }}
                  onRemove={() => removeFilter(fil.id)}
                  isOpen={showFilterOptions === fil.id}
                  isRequired={requiredFilters.includes(fil.type)}
                />
                {showFilterOptions === fil.type && (
                  <div className="tw-absolute tw-z-20 tw-mt-2 tw-w-80 tw-bg-white tw-rounded-lg tw-shadow-lg tw-border">
                    {renderFilterComponent(fil.type, fil.id)}
                  </div>
                )}
              </div>
            );
          })}
          {remainingFilters.length > 0 && (
            <div className="tw-relative filter-dropdown">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropdown(!showDropdown);
                  setShowFilterOptions(null);
                }}
                className="tw-flex tw-items-center tw-gap-1.5 tw-px-3 tw-py-1.5 tw-text-sm tw-text-gray-600  hover:tw-bg-gray-100 tw-rounded-lg"
              >
                <Plus className="tw-h-3.5 tw-w-3.5" />
                <span>More</span>
              </button>
              {showDropdown && (
                <div className="tw-absolute tw-z-10 tw-mt-2 tw-w-80 tw-bg-white tw-rounded-lg tw-shadow-lg tw-border">
                  <div className="tw-p-2">
                    <input
                      type="text"
                      className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
                      placeholder="Search filters..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div className="tw-max-h-60 tw-overflow-y-auto tw-pb-2">
                    {remainingFilters
                      .filter((filter) => filter.toLowerCase().includes(searchTerm.toLowerCase()))
                      .map((filter) => (
                        <button
                          key={filter}
                          onClick={() => addFilter(filter)}
                          className="tw-w-full tw-px-4 tw-py-2 tw-text-left hover:tw-bg-gray-100"
                        >
                          {filters[filter]?.label}
                        </button>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {hasAdvancedFilters?.length > 0 ? (
            <AdvancedFilters
              filters={filters}
              filterData={filterData}
              loadingFilters={loadingFilters}
              onAddFilter={onAddFilter}
              onFilterChange={handleAdvancedFilterChange}
              activeFilters={value.reduce((acc, curr) => {
                if (filters[curr.type].isAdvanced) {
                  acc.push(curr);
                }
                return acc;
              }, [])}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
