import { ADD_FILTER, REMOVE_FILTER, CHANGE_FILTER } from '../constants/actionTypes';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  vacancyList: {
    activeFilters: [
      {
        type: 'status',
        id: uuidv4(),
        operator: 'EQ',
        value: ['ACTIVE', 'AUTHORISATION_REJECTED'],
      },
    ],
    filterData: {},
    loadingFilters: [],
  },
};

const filterReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_FILTER: {
      const { filterName, filters } = payload;
      const newState = { ...state };
      if (newState[filterName]) {
        newState[filterName].activeFilters = filters;
      } else {
        newState[filterName] = {};
        newState[filterName].activeFilters = filters;
      }

      return {
        ...state,
        ...newState,
      };
    }

    case REMOVE_FILTER: {
      // // Assuming payload is a map of filterName to array of filters
      // Object.entries(payload).forEach(([filterName, filters]) => {
      //   ensureFilterContextExists(activeFiltersCopy, filterName);
      //   // Convert old format to new if needed
      //   if (Array.isArray(filters)) {
      //     activeFiltersCopy[filterName].activeFilters = [...activeFiltersCopy[filterName].activeFilters, ...filters];
      //   } else if (filters.activeFilters) {
      //     // Already in new format
      //     activeFiltersCopy[filterName] = filters;
      //   }
      // });
      // return {
      //   ...state,
      //   activeFiltersCopy,
      // };
    }

    case CHANGE_FILTER: {
      const { filterName, filters } = payload;
      const newState = { ...state };
      if (newState[filterName]) {
        newState[filterName].activeFilters = filters;
      }

      return {
        ...state,
        ...newState,
      };
    }

    default:
      return state;
  }
};

export default filterReducer;
