import React, { useState } from 'react';
import { Search, X, Check } from 'lucide-react';

const AdvancedMultiSelectFilter = ({ options = [], value = [], onChange, isSingleSelect = false }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleSelectAll = () => {
    const optionsToSelect = searchTerm ? filteredOptions : options;
    const newValues = optionsToSelect.map((o) => o.value);
    onChange(newValues);
  };

  const handleRemoveAll = () => {
    onChange([]);
  };

  const handleToggleOption = (optionValue) => {
    if (isSingleSelect) {
      onChange([optionValue]);
    } else {
      const newValue = value.includes(optionValue) ? value.filter((v) => v !== optionValue) : [...value, optionValue];
      onChange(newValue);
    }
  };

  const allFilteredSelected = filteredOptions.every((option) => value.includes(option.value));
  const noneFilteredSelected = filteredOptions.every((option) => !value.includes(option.value));

  return (
    <div className="tw-w-full tw-border tw-rounded-md tw-flex tw-flex-col">
      <div className="tw-p-2 tw-border-b">
        <div className="tw-relative">
          <input
            type="text"
            className="tw-w-full tw-pl-8 tw-pr-8 tw-py-1.5 tw-text-sm tw-border tw-rounded-md focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="tw-absolute tw-left-2 tw-top-1/2 -tw-translate-y-1/2 tw-h-4 tw-w-4 tw-text-gray-400" />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="tw-absolute tw-right-2 tw-top-1/2 -tw-translate-y-1/2 tw-flex tw-items-center tw-justify-center tw-text-gray-400 hover:tw-text-gray-600"
            >
              <X className="tw-h-4 tw-w-4" />
            </button>
          )}
        </div>
      </div>

      {!isSingleSelect && (
        <div className="tw-flex tw-gap-2 tw-p-2 tw-border-b">
          <button
            onClick={handleSelectAll}
            disabled={allFilteredSelected}
            className={`tw-flex-1 tw-text-xs tw-font-medium ${
              allFilteredSelected ? 'tw-text-gray-400 tw-cursor-default' : 'tw-text-blue-600 hover:tw-text-blue-700'
            }`}
          >
            Select all
          </button>
          <div className="tw-w-px tw-bg-gray-300" />
          <button
            onClick={handleRemoveAll}
            disabled={noneFilteredSelected}
            className={`tw-flex-1 tw-text-xs tw-font-medium ${
              noneFilteredSelected ? 'tw-text-gray-400 tw-cursor-default' : 'tw-text-blue-600 hover:tw-text-blue-700'
            }`}
          >
            Remove all
          </button>
        </div>
      )}

      <div className="tw-overflow-y-auto tw-max-h-[320px]">
        <div className="tw-flex tw-flex-col">
          {filteredOptions.map((option) => (
            <label
              key={option.value}
              className="tw-flex tw-items-center tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-cursor-pointer tw-border-b tw-last:border-b-0"
            >
              {isSingleSelect ? (
                <span className="tw-relative tw-flex tw-items-center tw-mr-3">
                  <input
                    type="radio"
                    checked={value.includes(option.value)}
                    className="tw-opacity-0 tw-absolute"
                    onChange={() => handleToggleOption(option.value)}
                  />
                  <span
                    className={`tw-w-4 tw-h-4 tw-rounded-full tw-border ${
                      value.includes(option.value) ? 'tw-border-blue-500 tw-bg-blue-500' : 'tw-border-gray-300'
                    } tw-flex tw-items-center tw-justify-center`}
                  >
                    {value.includes(option.value) && <span className="tw-w-2 tw-h-2 tw-rounded-full tw-bg-white" />}
                  </span>
                </span>
              ) : (
                <span className="tw-relative tw-flex tw-items-center tw-mr-3">
                  <input
                    type="checkbox"
                    checked={value.includes(option.value)}
                    className="tw-opacity-0 tw-absolute"
                    onChange={() => handleToggleOption(option.value)}
                  />
                  <span
                    className={`tw-w-4 tw-h-4 tw-rounded tw-border ${
                      value.includes(option.value) ? 'tw-border-blue-500 tw-bg-blue-500' : 'tw-border-gray-300'
                    } tw-flex tw-items-center tw-justify-center`}
                  >
                    {value.includes(option.value) && <Check className="tw-h-3 tw-w-3 tw-text-white" />}
                  </span>
                </span>
              )}
              <span dangerouslySetInnerHTML={{ __html: option.label }}></span>
            </label>
          ))}
          {filteredOptions.length === 0 && (
            <div className="tw-p-4 tw-text-center tw-text-gray-500 tw-text-sm">No options found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdvancedMultiSelectFilter;
