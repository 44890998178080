import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { retryableAPICall } from '@API/common-api-utils';
import { getLocation } from '@API/LocationAPI';
import { findUsersForAccount } from '@API/AccountAPI';
import { getEventType } from '@API/SchedulingAPI/EventTypeAPI';

import { CheckboxFilter } from '../../../Base/PageFilter/components';
import FilterBar from '../../../Base/PageFilter/FilterBar';

const FILTER_CONFIGS = {
  user: {
    component: CheckboxFilter,
    label: 'User',
    defaultValue: {
      operator: 'EQ',
      value: [],
    },
    loadOptions: async () => {
      const result = await findUsersForAccount(null, 'all');
      if (typeof result === 'string') {
        toast.error('Error loading Users, please try again later');
        return [];
      }
      return result.map((val) => ({ value: val.id, label: val.name }));
    },
  },
  eventType: {
    component: CheckboxFilter,
    label: 'Event Type',
    defaultValue: {
      operator: 'EQ',
      value: [],
    },
    loadOptions: async (aId) => {
      const resp = await retryableAPICall(() => getEventType());
      if (typeof resp === 'string') {
        toast.error('Error loading Event Types, please try again later');
        return [];
      }
      return resp
        .filter(({ sharedWith }) => {
          return sharedWith.map((a) => a.accountId).includes(aId);
        })
        .map(({ name, id }) => {
          return { value: id, label: name };
        });
    },
  },
  location: {
    component: CheckboxFilter,
    label: 'Location',
    defaultValue: {
      operator: 'EQ',
      value: [],
    },
    loadOptions: async (aId) => {
      const resp = await retryableAPICall(() => getLocation());
      if (typeof resp === 'string') {
        toast.error('Error loading Locations, please try again later');
        return [];
      }
      return resp
        .filter(({ type, sharedWith }) => {
          return (type === 'EVENT' || type === 'ALL') && sharedWith.map((a) => a.accountId).includes(aId);
        })
        .map(({ name, id }) => {
          return { value: id, label: name };
        });
    },
  },
};

const PageFilter = ({ filterValues, setFilterValues, accountId }) => {
  const [filterData, setFilterData] = useState({});
  const [loadingFilters, setLoadingFilters] = useState([]);

  const handleAddFilter = async (filterName) => {
    const filter = FILTER_CONFIGS[filterName];

    if (filter.loadOptions && !filterData[filterName]) {
      setLoadingFilters((prev) => [...prev, filterName]);

      try {
        const options = await filter.loadOptions(accountId);
        setFilterData((prev) => ({
          ...prev,
          [filterName]: { options },
        }));
      } catch (error) {
        console.error(`Failed to load options for ${filterName}:`, error);
        toast.error(`Failed to load ${filterName} options`);
      } finally {
        setLoadingFilters((prev) => prev.filter((f) => f !== filterName));
      }
    }
  };

  useEffect(() => {
    const initialFilter = 'user';
    if (FILTER_CONFIGS[initialFilter]?.loadOptions) {
      handleAddFilter(initialFilter);
    }
  }, []);

  return (
    <div className="tw-ml-[-20px] tw-mr-[-20px]">
      <FilterBar
        filters={FILTER_CONFIGS}
        filterData={filterData}
        loadingFilters={loadingFilters}
        onAddFilter={handleAddFilter}
        initialFilters={['user']}
        requiredFilters={['user']}
        onFilterChange={setFilterValues}
        value={filterValues}
      />
    </div>
  );
};

export default PageFilter;
