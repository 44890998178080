import React from 'react';
import { Plus } from 'lucide-react';
import FilterListItem from './FilterListItem';
import FilterEditor from './FilterEditor';
import GroupedFilterSelector from './GroupedFilterSelector';

const FilterContent = ({
  activeFilters,
  editingFilter,
  filters,
  filterData,
  loadingFilters,
  onAddFilter,
  onRemoveFilter,
  onUpdateFilter,
  setEditingFilter,
}) => {
  const getFilterLabel = (filterType) => {
    return filters[filterType]?.label || filterType;
  };

  const getAvailableFilters = () => {
    return filters;
  };

  return (
    <div className="tw-flex tw-min-h-[500px]">
      <div className="tw-w-1/2 tw-border-r tw-overflow-y-auto">
        <div className="tw-border-b tw-bg-gray-50 tw-p-3">
          <h5 className="tw-text-sm tw-font-medium tw-text-gray-700">Active filters</h5>
        </div>
        {activeFilters.map((filter) => (
          <FilterListItem
            key={filter.type}
            filter={filter}
            isActive={editingFilter === filter.type}
            filterLabel={getFilterLabel(filter.type)}
            filterData={filterData}
            onSelect={() => {
              setEditingFilter(filter.id);
            }}
            onRemove={onRemoveFilter}
          />
        ))}
        <div className="tw-p-3">
          <button
            onClick={() => setEditingFilter('add-new')}
            className="tw-flex tw-items-center tw-gap-1.5 tw-text-sm tw-text-blue-600 hover:tw-text-blue-700"
          >
            <Plus className="tw-h-3.5 tw-w-3.5" />
            <span>Add filter</span>
          </button>
        </div>
      </div>
      <div className="tw-w-1/2 tw-overflow-y-auto">
        {editingFilter === 'add-new' ? (
          <GroupedFilterSelector
            filters={getAvailableFilters()}
            onSelectFilter={onAddFilter}
            onCancel={() => setEditingFilter(null)}
          />
        ) : editingFilter ? (
          <FilterEditor
            filter={activeFilters.find((a) => a.id === editingFilter)}
            filters={filters}
            filterType={activeFilters.find((a) => a.id === editingFilter).type}
            filterData={filterData}
            loadingFilters={loadingFilters}
            onUpdateFilter={onUpdateFilter}
          />
        ) : (
          <div className="tw-flex tw-items-center tw-justify-center tw-h-full tw-text-gray-500 tw-text-sm">
            Select a filter to edit or add a new filter
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterContent;
