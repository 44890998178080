import React, { useState, useEffect } from 'react';
import { SlidersHorizontal } from 'lucide-react';
import { ModalPopup } from '@Base/Modal';
import FilterContent from './FilterContent';

const AdvancedFilters = ({
  filters = {},
  filterData = {},
  loadingFilters = [],
  onAddFilter,
  onFilterChange,
  activeFilters = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localActiveFilters, setLocalActiveFilters] = useState(activeFilters);
  const [editingFilter, setEditingFilter] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setLocalActiveFilters(activeFilters);
      setEditingFilter(null);
    }
  }, [isOpen]);

  const handleLoadFilterOptions = async (filterType, nId) => {
    const filter = filters[filterType];

    if (filterData[filterType] || !filter.loadOptions) {
      return;
    }

    try {
      await onAddFilter?.(filterType, nId);
    } catch (error) {
      console.error(`Failed to load options for ${filterType}:`, error);
    }
  };

  const handleAddFilter = async (filterType) => {
    const nId = `${filterType}-${Date.now()}`;
    setEditingFilter(nId);
    const defaultOperator = filters[filterType].optionsAllowed?.[0] || 'EQ';

    setLocalActiveFilters([
      ...localActiveFilters,
      {
        id: nId,
        type: filterType,
        operator: defaultOperator,
        value: [],
      },
    ]);
    await handleLoadFilterOptions(filterType, nId);
  };

  const handleRemoveFilter = (filterId) => {
    const newFilters = [...localActiveFilters].filter((a) => a.id !== filterId);
    setEditingFilter(null);
    setLocalActiveFilters(newFilters);
  };

  const handleUpdateFilter = (filterId, updates) => {
    const trrt = localActiveFilters.reduce((acc, cur) => {
      if (cur.id === updates.id) {
        acc.push(updates);
      } else {
        acc.push(cur);
      }
      return acc;
    }, []);
    setLocalActiveFilters(trrt);
  };

  const handleApplyFilters = () => {
    onFilterChange(localActiveFilters);
    setIsOpen(false);
  };

  const activeFiltersCount = activeFilters.length;
  const hasActiveFilters = activeFiltersCount > 0;

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className={`tw-flex tw-items-center tw-gap-1.5 tw-px-3 tw-py-1.5 tw-text-sm tw-text-gray-600 hover:tw-bg-gray-100 tw-rounded-lg ${
          hasActiveFilters ? 'tw-bg-[#1A2E56]/25 tw-border tw-border-[#1A2E56]/10' : 'tw-bg-gray-100'
        }`}
      >
        <SlidersHorizontal
          className={`tw-h-4 tw-w-4 ${hasActiveFilters ? 'tw-text-[#1A2E56]/60' : 'tw-text-gray-500'}`}
        />
        <span
          className={`${hasActiveFilters ? 'tw-font-medium tw-text-[#1A2E56]' : 'tw-font-normal tw-text-gray-700'}`}
        >
          Advanced filters
        </span>
        {hasActiveFilters && <span className="tw-ml-0.5 tw-text-[#1A2E56]/70">({activeFiltersCount})</span>}
      </button>

      <ModalPopup
        isOpen={isOpen}
        onToggle={() => setIsOpen(false)}
        title="All Filters"
        size="lg"
        onOkay={handleApplyFilters}
        submitText="Apply Filters"
        cancelText="Cancel"
        customBodyClass="tw-p-0"
      >
        <FilterContent
          activeFilters={localActiveFilters}
          editingFilter={editingFilter}
          filters={filters}
          filterData={filterData}
          loadingFilters={loadingFilters}
          onSelectFilter={setEditingFilter}
          onAddFilter={handleAddFilter}
          onRemoveFilter={handleRemoveFilter}
          onUpdateFilter={handleUpdateFilter}
          setEditingFilter={setEditingFilter}
        />
      </ModalPopup>
    </>
  );
};

export default AdvancedFilters;
