import React from 'react';
import { X } from 'lucide-react';

const FilterListItem = ({ filter, isActive, filterLabel, filterData, onSelect, onRemove }) => {
  const getOperatorDisplay = (operator) => {
    const operatorMap = {
      EQ: 'is equal to',
      NE: 'is not equal to',
      IN: 'is equal to any of',
      NIN: 'is not equal to any of',
      LT: 'is before',
      LTE: 'is on or before',
      GT: 'is after',
      GTE: 'is on or after',
    };

    return operatorMap[operator] || operator;
  };

  const getFilterDisplayValue = (filter) => {
    if (!filter.value || filter.value.length === 0) {
      return '';
    }

    if (
      filter.type === 'dateApplied' ||
      filter.type === 'statusUpdatedDate' ||
      filter.type.toLowerCase().includes('date')
    ) {
      if (filter.operator === 'between' && filter.value.startDate && filter.value.endDate) {
        const startDate = new Date(filter.value.startDate).toLocaleDateString();
        const endDate = new Date(filter.value.endDate).toLocaleDateString();
        return `${startDate} to ${endDate}`;
      } else if (filter.value.startDate) {
        return new Date(filter.value.startDate).toLocaleDateString();
      }
      return '';
    }

    const options = filterData[filter.type]?.options || [];

    if (filter.value.length === 1) {
      const option = options.find((o) => o.value === filter.value[0]);
      return option ? option.label : filter.value[0];
    } else if (filter.value.length > 1) {
      const selectedLabels = filter.value.map((value) => {
        const option = options.find((o) => o.value === value);
        return option ? option.label : value;
      });

      if (selectedLabels.length <= 3) {
        return selectedLabels.join(', ');
      } else {
        return `${selectedLabels.slice(0, 3).join(', ')} ... ${selectedLabels.length - 3} more`;
      }
    }

    return '';
  };

  return (
    <div
      className={`tw-p-3 tw-border-b tw-last:border-b-0 tw-cursor-pointer ${isActive ? 'tw-bg-blue-50' : ''}`}
      onClick={onSelect}
    >
      <div className="tw-flex tw-items-center tw-justify-between">
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-items-center tw-gap-2">
            <span className="tw-text-sm tw-font-medium">{filterLabel}</span>
            <span className="tw-text-xs tw-text-gray-500">{getOperatorDisplay(filter.operator)}</span>
          </div>
          {filter.value && filter.value.length > 0 && (
            <div className="tw-text-xs tw-text-blue-600 tw-mt-1">{getFilterDisplayValue(filter)}</div>
          )}
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onRemove(filter.id);
          }}
          className="tw-text-gray-400 hover:tw-text-gray-600"
        >
          <X className="tw-h-4 tw-w-4" />
        </button>
      </div>
    </div>
  );
};

export default FilterListItem;
