import React from 'react';
import AdvancedMultiSelectFilter from './AdvancedMultiSelectFilter';
import OptimizedDateFilter from './OptimizedDateFilter';

const FilterEditor = ({ filter, filterType, filters, filterData, loadingFilters, onUpdateFilter }) => {
  const getOperatorsForFilter = (fType) => {
    const allowedOperators = filters[fType]?.optionsAllowed || ['EQ'];

    const operatorMap = {
      EQ: { value: 'EQ', label: 'is equal to' },
      NE: { value: 'NE', label: 'is not equal to' },
      IN: { value: 'IN', label: 'is equal to any of' },
      NIN: { value: 'NIN', label: 'is not equal to any of' },
      LT: { value: 'LT', label: 'is before' },
      LTE: { value: 'LTE', label: 'is on or before' },
      EQ: { value: 'EQ', label: 'is exactly' },
      GT: { value: 'GT', label: 'is after' },
      GTE: { value: 'GTE', label: 'is on or after' },
    };

    return allowedOperators.map((op) => operatorMap[op]).filter(Boolean);
  };

  const getFilterComponentForOperator = (fType, operator) => {
    if (fType === 'dateApplied' || fType === 'statusUpdatedDate' || fType.toLowerCase().includes('date')) {
      return (props) => <OptimizedDateFilter {...props} operator={operator} />;
    }

    switch (operator) {
      case 'EQ':
      case 'NE':
        return (props) => <AdvancedMultiSelectFilter {...props} isSingleSelect={true} />;
      case 'IN':
      case 'NIN':
      default:
        return (props) => <AdvancedMultiSelectFilter {...props} isSingleSelect={false} />;
    }
  };

  const getFilterLabel = (fType) => {
    return filters[fType]?.label || fType;
  };

  const isLoading =
    loadingFilters.includes(filterType) || (filters[filterType]?.loadOptions && !filterData[filterType]);

  if (isLoading) {
    return (
      <div className="tw-p-4 tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-48">
        <div className="tw-animate-spin tw-h-8 tw-w-8 tw-border-4 tw-border-blue-500 tw-border-t-transparent tw-rounded-full tw-mx-auto" />
        <div className="tw-mt-4 tw-text-sm tw-text-gray-600">Loading filter options...</div>
      </div>
    );
  }

  const operators = getOperatorsForFilter(filterType);
  const FilterComponent = getFilterComponentForOperator(filterType, filter?.operator);
  const options = filterData[filterType]?.options || [];

  if (!FilterComponent) {
    return <div className="tw-p-3 tw-text-sm tw-text-gray-500">Filter component not found</div>;
  }

  const dateOperatorMap = {
    LT: 'is before',
    LTE: 'is on or before',
    EQ: 'is exactly',
    GT: 'is after',
    GTE: 'is on or after',
  };

  const isDateFilter =
    filterType === 'dateApplied' || filterType === 'statusUpdatedDate' || filterType.toLowerCase().includes('date');

  return (
    <div className="tw-max-h-full">
      <div className="tw-p-4 tw-border-b">
        <h4 className="tw-text-base tw-font-medium tw-text-gray-700">Edit filter</h4>
      </div>

      <div className="tw-p-3">
        <div className="tw-flex tw-items-center tw-gap-2 tw-mb-4">
          <span className="tw-font-medium tw-text-gray-700">{getFilterLabel(filterType)}</span>
          <select
            className="tw-flex-1 tw-border tw-rounded-md tw-px-2 tw-py-1.5 tw-text-sm"
            value={filter.operator}
            onChange={(e) => onUpdateFilter(filterType, { ...filter, operator: e.target.value, value: [] })}
          >
            {operators.map((op) => (
              <option key={op.value} value={op.value}>
                {isDateFilter && dateOperatorMap[op.value] ? dateOperatorMap[op.value] : op.label}
              </option>
            ))}
          </select>
        </div>

        <div className="tw-mt-2 tw-w-full tw-max-h-50">
          {isDateFilter ? (
            <FilterComponent
              value={filter.value}
              onChange={(dateValue) => {
                onUpdateFilter(filterType, { ...filter, value: dateValue });
              }}
            />
          ) : (
            <FilterComponent
              options={options}
              value={filter.value}
              onChange={(value) => {
                onUpdateFilter(filterType, { ...filter, value });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterEditor;
