import React from 'react';
import { ChevronDown, X } from 'lucide-react';
import Tippy from '@tippyjs/react';

const FilterButton = ({ value = [], options = [], onClick, onRemove, isOpen, isRequired, label }) => {
  const hasSelections = Array.isArray(value) && value.length > 0;
  const getSelectedLabels = () => {
    if (!Array.isArray(value)) return '';

    return value.map((val) => options.find((opt) => opt.value === val)?.label).filter(Boolean);
  };

  const selectedLabels = getSelectedLabels();

  const tooltipContent = selectedLabels.length ? (
    <div className="tw-text-sm">
      <div className="tw-font-medium tw-mb-1 tw-pb-1 tw-border-b tw-border-gray-200">{label}</div>
      <div className="tw-text-gray-600">{selectedLabels.join(', ')}</div>
    </div>
  ) : null;

  return (
    <Tippy
      content={tooltipContent}
      disabled={!tooltipContent}
      arrow={false}
      placement="bottom-start"
      className="!tw-bg-white !tw-text-gray-900 !tw-shadow-lg !tw-border !tw-px-3 !tw-py-2 !tw-rounded-lg"
    >
      <div
        onClick={onClick}
        className={`tw-cursor-pointer tw-flex tw-items-center tw-gap-2 tw-px-3 tw-py-1.5 tw-border tw-rounded-lg tw-cursor-pointer
    ${hasSelections ? 'tw-bg-[#1A2E56]/25 tw-border tw-border-[#1A2E56]/10' : 'tw-bg-gray-100'}`}
      >
        <span className="tw-text-sm">
          <span className={`${hasSelections ? 'tw-font-medium tw-text-[#1A2E56]' : 'tw-font-normal tw-text-gray-700'}`}>
            {label}
          </span>
          {hasSelections && <span className="tw-ml-1.5 tw-text-[#1A2E56]/70">({value.length})</span>}
        </span>
        <ChevronDown className={`tw-h-4 tw-w-4 ${hasSelections ? 'tw-text-[#1A2E56]/60' : 'tw-text-gray-500'}`} />
        {!isRequired && (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
            className={`tw-ml-0.5 tw-h-4 ${
              hasSelections
                ? 'tw-text-[#1A2E56]/50 hover:tw-text-[#1A2E56]/70'
                : 'tw-text-gray-400 hover:tw-text-gray-600'
            }`}
          >
            <X className="tw-w-4 tw-mt-[-7px]" />
          </button>
        )}
      </div>
    </Tippy>
  );
};

export default FilterButton;
